import { Permission, PermissionType } from './../../Models/JWTTokenResponse.model';
import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from 'src/app/auth/login.service';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
  code?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavService {
  public screenWidth: any;
  public collapseSidebar: boolean = true;
  public fullScreen = false;
  public items: BehaviorSubject<Menu[]>;
  constructor(private loginService: LoginService) {
    this.onResize();
    if (this.screenWidth < 991) {
      this.collapseSidebar = true;
    }
    const userCodes = this.loginService.getUserCodes();
    const filteredMenuItems = this.filterMenuItems(this.MENUITEMS, userCodes);
    this.items = new BehaviorSubject<Menu[]>(filteredMenuItems);
  }

  private filterMenuItems(menuItems: Menu[], userCodes: any[]): Menu[] {
    return menuItems.filter(menuItem => {
      // Check if the menu item should be shown
      const shouldShow = !userCodes.some(userCode =>
        userCode.securityCode === menuItem.code && userCode.permission === PermissionType.Deny
      );

      // If the menu item has children, filter them too
      if (menuItem.children) {
        menuItem.children = this.filterMenuItems(menuItem.children, userCodes);
      }

      return shouldShow;
    });
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  MENUITEMS: Menu[] = [
    {
      title: 'Products',
      icon: 'home',
      type: 'sub',
      active: true,
      children: [
        { path: './products/list', title: 'List', type: 'link'
        // , code:'get-products-list' 
      },
        { path: './products/mapping', title: 'Mapping', type: 'link', code: 'mapping' },
        { path: './products/brands', title: 'Brands', type: 'link' }       
      ],
    },
    {
      title: 'User Managment',
      icon: 'user',
      type: 'sub',
      active: false,
      code: 'users' ,
      children: [
        { path: './admin/users', title: 'Users', type: 'link', code: 'users' },
        { path: './admin/organizations', title: 'Organizations', type: 'link' },
      ],
    },
    {
      title: 'Products Listing',
      icon: 'list',
      type: 'link',
      active: false,
      path: './listing',
      code : 'product-listing-functionality'
    },
    {
      title: 'Client Integration',
      icon: 'cloud',
      type: 'sub',
      active: false,
      code : 'client-integration',
      children: [
        { path: './clientIntegration/accessToken', title: 'Access Token', type: 'link' },
        // { path: './clientIntegration/Products', title: 'Products', type: 'link' },
      ],
    },
    {
      title: 'Warehouse',
      icon: 'package',
      type: 'link',
      active: false,
      path: './warehouse',
      code : 'warehouse'
    },
  ];
}
