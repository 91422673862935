import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListingRoutingModule } from './product-listing-routing.module';
import { ProductListingListComponent } from './product-listing-list/product-listing-list.component';
import { ProductListingFormComponent } from './product-listing-form/product-listing-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CriteriaComponent } from './criteria/criteria.component';
import { NgbCollapseModule, NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MappingRoutingModule } from '../mapping/mapping-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { NgxPrintModule } from 'ngx-print';
import { NgxSliderModule } from 'ngx-slider-v2';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { StepsComponent } from './steps/steps.component';



@NgModule({
  declarations: [ProductListingListComponent, ProductListingFormComponent, CriteriaComponent, StepsComponent],
  imports: [
    CommonModule, ProductListingRoutingModule, ReactiveFormsModule, NgbDropdownModule,
    CarouselModule,
    MappingRoutingModule,
    NgbModule,
    CarouselModule,
    HttpClientModule,
    FormsModule,
    NgxPrintModule,
    NgxSliderModule,
    GalleryModule,
    NgbCollapseModule,
  ]
})
export class ProductListingModule { }
